import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '../assets/sass/style.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './ScrollToTop';
import Header from './Header';
import Promo from './Promo';
import ShortInfo from './ShortInfo';
import AboutCourse from './AboutCourse';
import Portfolio from './Portfolio';
import LearningProcess from './LearningProcess';
import Results from './Results';
import CourseProgram from './CourseProgram';
import StudentsWinners from './StudentsWinners';
import Mentors from './Mentors';
import Technologies from './Technologies';
import SendRequest from './SendRequest';
import Feedback from './Feedback';
import Questions from './Questions';
import Footer from './Footer';
import ModalCourses from './ModalCourses';
import ModalForm from './ModalForm';
import moment from 'moment';
import { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MaskInput from './maskInput';
// import PrivacyPolicy from '../pages/PrivacyPolicy';

const App = () => {
  MaskInput();
  AOS.init({
    delay: 150,
    duration: 500,
    easing: 'ease',
    once: false
  });

  const startCourseDate = null; //Дата старта курса
  const discountLastDay = '2023-02-19'; //Последний день скидок
  const discountEndDate = '2023-02-20'; //День окончания скидок

  const discountValue = 20; //Процент скидки

  let price1 = 20000; //Цена первого модуля
  let price2 = 98000; //Цена второго модуля
  let price3 = 250000; //Цена третьего модуля

  let creditPrice1 = Math.round(price1 / 12); //Цена в рассрочку без скидки - модуль 2
  let creditPrice2 = Math.round(price2 / 12); //Цена в рассрочку без скидки - модуль 2
  let creditPrice3 = Math.round(price3 / 12); //Цена в рассрочку без скидки - модуль 3

  //Расчет скидок
  const calculateDiscountPrice = stagePrice => {
    if (!moment().isAfter(discountEndDate)) {
      let discountPrice = Math.round(stagePrice - stagePrice * (discountValue / 100));
      return discountPrice;
    } else {
      return stagePrice;
    }
  };

  //Цены на модули с учетом скидки
  let finalPriceStage1 = calculateDiscountPrice(price1);
  let finalPriceStage2 = calculateDiscountPrice(price2);
  let finalPriceStage3 = calculateDiscountPrice(price3);

  //Цены на модули в рассрочку с учетом скидки
  let creditPriceWithDiscount1 = Math.round(finalPriceStage1 / 12);
  let creditPriceWithDiscount2 = Math.round(finalPriceStage2 / 12);
  let creditPriceWithDiscount3 = Math.round(finalPriceStage3 / 12);

  const prices = {
    price1,
    price2,
    price3,
    finalPriceStage1,
    finalPriceStage2,
    finalPriceStage3,
    creditPrice2,
    creditPrice3,
    creditPriceWithDiscount1,
    creditPriceWithDiscount2,
    creditPriceWithDiscount3
  };

  const [modalForm, setModalForm] = useState(false);
  const [modalCourses, setModalCourses] = useState(false);

  const openModalForm = () => {
    setModalForm(!modalForm);
    setSelectProgram('Логика')
  };

  const openModalCourses = () => {
    setModalCourses(!modalCourses);
  };

  const [selectProgram, setSelectProgram] = useState('');

  const setModalPrice = () => {
    if (selectProgram === 'Вводный') {
      return {
        price1,
        finalPriceStage1,
        creditPrice1,
        creditPriceWithDiscount1
      };
    } else if (selectProgram === 'Вёрстка') {
      return {
        price2,
        finalPriceStage2,
        creditPrice2,
        creditPriceWithDiscount2
      };
    } else if (selectProgram === 'Логика') {
      return {
        price3,
        finalPriceStage3,
        creditPrice3,
        creditPriceWithDiscount3
      };
    }
  };

  return (
    <>
      <Router>
        <ScrollToTop />
        <Header openModal={openModalForm} />
        <Route
          path='/'
          exact={true}
          render={() => {
            return (
              <>
                <Promo
                  openModal={openModalForm}
                  startCourseDate={startCourseDate}
                  discountLastDay={discountLastDay}
                  discountEndDate={discountEndDate}
                  discountPercent={discountValue}
                />
                <ShortInfo startCourseDate={startCourseDate} openModal={openModalCourses} />
                <AboutCourse />
                <Portfolio />
                <LearningProcess />
                <Results />
                <CourseProgram
                  price1={finalPriceStage1}
                  price2={finalPriceStage2}
                  price3={finalPriceStage3}
                  openModal={openModalForm}
                  setSelectProgram={setSelectProgram}
                />
                <StudentsWinners />
                {/* <Mentors /> */}
                <Technologies />
                {/* <SendRequest
                  prices={prices}
                  discountLastDay={discountLastDay}
                  discountEndDate={discountEndDate}
                  discountPercent={discountValue}
                /> */}
                <Feedback />
                <Questions startCourseDate={startCourseDate} />
                <Footer openModal={openModalForm} />
                {modalCourses ? (
                  <ModalCourses
                    closeModal={openModalCourses}
                    price1={finalPriceStage1}
                    price2={finalPriceStage2}
                    price3={finalPriceStage3}
                    setSelectProgram={setSelectProgram}
                    openModalForm={openModalForm}
                  />
                ) : null}
                {modalForm ? (
                  <ModalForm
                    closeModal={openModalForm}
                    discountEndDate={discountEndDate}
                    selectProgram={selectProgram}
                    setModalPrice={setModalPrice}
                  />
                ) : null}
              </>
            );
          }}
        />
        {/* <Route path='/privacy-policy' component={PrivacyPolicy} /> */}
      </Router>
    </>
  );
};

export default App;
